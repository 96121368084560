import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';

import AxiosApiClient from '../../utils/AxiosApiClient';
import AuthUtility from './AuthUtility';
import LoadingSpinner from '../LoadingSpinner';

import axios from 'axios';
import swal from 'sweetalert';

import google_logo from '../../../assets/frontend/images/google_logo.png';
import facebook_logo from '../../../assets/frontend/images/facebook_logo.png';
import apple_logo from '../../../assets/frontend/images/apple_logo.png';

const error_style = 'font-raleway font-small font-weight-500 txt-000 bg-light-red p-5';

function SingleSignOn(){
	
	const navHistory = useNavigate();

	// using hooks
    const [isLoading, setIsLoading] = React.useState(false);
	const [loginInput, setLogin] = useState({
		email: localStorage.getItem('auth_email') ? localStorage.getItem('auth_email') : '',
		password: localStorage.getItem('password') ? localStorage.getItem('password') : '',
		errorList: [],
		errorStyle: [],
	});

	const [isChecked, setIsChecked] = useState(localStorage.getItem('remember_me') && localStorage.getItem('remember_me') === 'true' ? true : false);
	
	const handleInput = (event) => {
		event.persist();
		
		if(event.target.name !== 'rememberMe'){
			setLogin({...loginInput, [event.target.name]: event.target.value });
		}
		
		//remove the target error message no matter the new input, it will be validated on the server
		if (loginInput.errorList.hasOwnProperty(event.target.name)){
			delete loginInput.errorList[event.target.name];
			delete loginInput.errorStyle[event.target.name];
		}
	}

	const loginSubmit = (event) => {
		event.preventDefault();
		
		setIsLoading(true);
			
		//values sent to api
		const payload = {
			email: loginInput.email,
			password: loginInput.password,
		}

		var auth_api = 'phpLaravel';
		if(localStorage.getItem('auth_api') && localStorage.getItem('auth_api') != ''){
			auth_api = localStorage.getItem('auth_api');
		}

        const fetchData = async () => {
            try {
              const { apiClient, getBearerToken, makeRequestWithHeaders } = AxiosApiClient({ apiBackend: auth_api, token: localStorage.getItem('auth_token') });
              await getBearerToken();
              const response = await makeRequestWithHeaders('post', '/api/login', payload, {});

              setApiData(response);
            } catch (error) {
              handleApiError(error);
            }
        };
      
        fetchData();

	}
    function setApiData(response){
		
		if(response && response.data){
			if(response.data.status === 200){//HTTP_OK
				console.log('loginSubmit: ',response.data.message);
			
				AuthUtility.setAuthData(response.data.auth_users_name, response.data.auth_users_last_name, 
				response.data.auth_email, response.data.auth_token, response.data.auth_role, response.data.auth_api, response.data.auth_paypal_email,
				loginInput.password, isChecked);
				
				//redirect to proper dashboard based on role
				if(response.data.auth_role === 'admin'){
					navHistory('/admin/dashboard');
				}else if(response.data.auth_role === 'member'){
					navHistory('/member/dashboard');
				}else{
					navHistory('/');
				}
				
			}else if(response.data.status === 401){//HTTP_UNAUTHORIZED
				console.log('loginSubmit error: ',response.data.message + ' back-end api call error');
		
				//user not authenticated on server so remove from local storage
				AuthUtility.clearAuthData();
				
				swal("Warning",response.data.message,"warning");

			}else if(response.data.status === 800){//HTTP_FORM_VALIDATION_FAILED
				
				var errorStyleTemp = JSON.parse(JSON.stringify(response.data.validation_errors));
				Object.keys(errorStyleTemp).map((key) => (
					errorStyleTemp[key] = error_style
				));
				
				//validation errors mapped to input fields
				setLogin({...loginInput, errorList: response.data.validation_errors, errorStyle: errorStyleTemp });
				
			}else{//more errors
			}
		}

		setIsLoading(false);
	}
    function handleApiError(error){
		console.log('loginSubmit error: ',error + ' back-end api call error');
	
		//user not authenticated on server so remove from local storage
		AuthUtility.clearAuthData();
					
		setIsLoading(false);

		swal("Error",error,"error");
    }

	const handleCheckboxChange = (event) => {
	    const { checked } = event.target;
	    setIsChecked(checked);
	    localStorage.setItem('remember_me', checked);
	};
	
	return(
		<div className="panel medium pt-20  pb-120l-100s">
			<div className="grid-x bg-fff b1-ddd p-20l-10s">
				<div className="large-12 medium-12 small-12 cell text-center">
					<div className="font-raleway font-large-medium-mr font-weight-700 txt-333 underline uppercase">Social Media Account</div>
					{/*<div className="font-raleway font-medium font-weight-500 txt-222 text-center p-10">Use your social media account to Login</div>*/}
					<div className="font-raleway font-medium font-weight-500 txt-222 text-center p-10">Coming Soon</div>
					
					<div id="sign_in_info_and_error" className="font-raleway font-medium text-center ptb-10 hide"></div>
				</div>
				{/*
				<div className="large-4 medium-4 small-12 cell text-center pt-10">
					<Link to="/register" className="button social-media text-left">
						<div className="clearfix">
						<img src={google_logo} width="20" alt="google logo" className="left"/>
						<span className="font-standard letter-spacing-0px pt-5 left">&nbsp;&nbsp;Login with Google</span>
						</div>
					</Link>
				</div>
				<div className="large-4 medium-4 small-12 cell text-center pt-10">
					<Link to="/register" className="button social-media text-left">
						<div className="clearfix">
						<img src={facebook_logo} width="20" alt="facebook logo" className="left"/>
						<span className="font-standard letter-spacing-0px pt-5 left">&nbsp;&nbsp;Login with Facebook</span>
						</div>
					</Link>
				</div>
				<div className="large-4 medium-4 small-12 cell text-center pt-10">
					<Link to="/register" className="button social-media text-left">
						<div className="clearfix">
						<img src={apple_logo} width="20" alt="apple logo" className="left"/>
						<span className="font-standard letter-spacing-0px pt-5 left">&nbsp;&nbsp;Login with Apple</span>
						</div>
					</Link>
				</div>
				*/}
			</div>
		</div>
	);
}

export default SingleSignOn;
